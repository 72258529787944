.select {
	width: 200px;
}

.chart-type-panel {
	height: 100%;
	float: left;
	font-size: 16px;
	margin-left: 10px;
}

.chart-time-panel {
	height: 40px;
	display: table;
	margin: 0;
	padding: 0;
	list-style-type: none;
	float: left;
}

.chart-time-panel li {
	display: table-cell;
	background-color: #e3e5e8;
	border-left: 1px solid #d2d6d8;
	padding: 0 20px;
	cursor: pointer;
	text-decoration: none;
	vertical-align: middle;
}

.chart-type-panel .ui-selectmenu-button {
	height: 100%;
	box-sizing: border-box;
	background: white none;
	border-radius: 0;
	border-color: #232157;
	color: #232157;
	display: table;
	outline: 0;
}

.chart-type-panel .ui-selectmenu-button.ui-selectmenu-button > * {
	display: table-cell;
	vertical-align: middle;
}

.chart-type-panel .ui-selectmenu-button.ui-selectmenu-button > .ui-icon-triangle-1-s {
	right: 0;
}

.chart-type-panel .ui-selectmenu-button.ui-selectmenu-button > .ui-selectmenu-text {
	padding: 0 20px 0 15px;
	min-width: 60px;
}

.select2-dropdown {
	border-radius: 0 !important;
}

.select2-container--default .select2-selection--single {
	border-radius: 0 !important;
	height: 40px !important;
}

.select2-container--default .select2-selection--multiple {
	border-radius: 0 !important;
}

.select2-selection__arrow {
	background: url('../../../site-default/modules_site/site/images/arrow-icon.svg') no-repeat center !important;
	background-size: 50% !important;
	-webkit-transform: rotate(180deg) !important;
	-ms-transform: rotate(180deg) !important;
	transform: rotate(180deg) !important;
}

.select2-selection__arrow b {
	display: none !important;
}

.select2-container--default .select2-search--inline .select2-search__field {
	font-size: 16px;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
	line-height: 42px !important;
}
#marketNow .select2-container--default .select2-selection--single .select2-selection__rendered {
	padding-left: 16px !important;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
	height: 40px !important;
	margin: 0 8px;
}

.chart-heading .active {
	background-color: #232157;
	color: white;
}

.chart-heading {
	float: left;
	position: relative;
	z-index: 1;
	white-space: nowrap;
	color: #232157;
	font-size: 16px;
	margin-bottom:16px;
}

@media (max-width: 766px) {
	.chart-heading {
		font-size: 14px;
	}
}

.chart-heading li {
	padding: 0 10px;
}

.chart-manager-root {
	color: #232157;
	font-size: 16px;
}
@media (min-width: 766px) and (max-width: 1100px) {
	.chart-manager-root {
		font-size: 14px;
	}
}

.chart-elemenet {
	clear: both;
}

.chart-infobar {
	line-height: 39px;
}
.chart-infobar.simple {
	font-size: 18px;
}
@media (max-width: 1100px) {
	.chart-infobar.simple {
		font-size: 16px;
	}
}
@media (max-width: 766px) {
	.chart-infobar.simple {
		font-size: 21px;
	}
}

.chart-infobar.full {
	font-size: 18px;
	float: right;
}

@media (min-width: 766px) and (max-width: 1100px) {
	.chart-infobar.full {
		/*font-size: 14px;*/
		font-size: 16px;
	}
}

@media (max-width: 766px) {
	.chart-infobar.full {
		clear:both;
	}
}

.chart-infobar .last-data-value2 {
	font-size: 18px;
}

@media (max-width: 766px) {
	.chart-infobar .last-data-value2 {
		font-size: 16px;
	}
}


.chart-infobar > span {
	display: inline-block;
	margin-bottom: 10px;
	margin-left: 20px;
	white-space: nowrap;
}

.chart-infobar .last-data-date {
	color: #8A8A8A
}

.chart-infobar .change-minus {
	color: #CC4533
}

.chart-infobar .change-plus {
	color: #50924D
}

/* ############################## */

.dialog-container .chart-title {
	display: block;
	font-size: 1em;
}

.dialog-container .chart-infobar {
	text-align: left;
	font-size: 0.95em;
}

/* ############################## */

@media (max-width: 1010px) {
	.tab-container .chart-heading {
		width: 100%;
	}
}

.tab-container .chart-heading .chart-time-panel li {
	padding-left: 10px;
	padding-right: 10px;
}
.tab-container .chart-heading .chart-type-panel {
	margin-left: 20px;
}

@media (max-width: 575px) {
	.tab-container .chart-heading .chart-time-panel li {
		padding-left: 5px;
		padding-right: 5px;
	}
	.chart-time-panel {
		width: 100%;
		float: left;
	}
	.chart-time-panel > * {
		text-align: center;
	}
	.chart-type-panel {
		float: left !important;
		width: 100%;
		margin-top: 24px;
		margin-left: 0 !important;
	}
	.chart-type-panel > span {
		width: 100% !important;
	}
	.chart-type-panel > span > span {
		width: 100% !important;
	}
}

.chart-infobar .note {
	position: absolute;
	z-index: 100;
	right: 16px;
	bottom: -52px;
	font-size: 14px;
	color:rgba(35,33,87,0.85);
	display:block;
	min-width:320px;
	margin:0!important;
}

@media(max-width:480px) {
	.chart-infobar .note {
		position:static;
		min-width:100%;
	}
}